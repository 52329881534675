import { Component } from 'vue-property-decorator'
import { GForm } from '@/components/forms/GForm'
import { fixDate } from '@/utils/general'
import dayjs from 'dayjs'

@Component
export class EvaluationView extends GForm {
  evaluationStatus = {
    pending: null,
    closed: null,
  }

  evaluationClosingReason = {
    signed: null,
    resigned: null,
  }

  async mounted () {
    this.evaluationStatus.closed = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'evaluation' } } }, { status: { name: { _eq: 'closed' } } }] },
    })

    this.evaluationClosingReason.signed = await this.fetchData({
      query: { name: 'find', model: 'ClosingReason' },
      filter: { _and: [{ type: { name: { _eq: 'signed' } } }, { status: { process: { table_name: { _eq: 'evaluation' } } } }] },
    })

    this.evaluationClosingReason.resigned = await this.fetchData({
      query: { name: 'find', model: 'ClosingReason' },
      filter: { _and: [{ type: { name: { _eq: 'resigned' } } }, { status: { process: { table_name: { _eq: 'evaluation' } } } }] },
    })
  }

  async updateEvaluation (viewData, financing) {
    const {
      evaluation: { id, status, offers },
      documentId,
      idProcess,
      formData: { acquirer, letterApproval, prohibition, quotas, operationNumber, approvalDate },
      idFieldDocument,
      prohibitionProperties,
      approvalProperties,
      evaluation,
    } = viewData

    if (!status.isClosed) {
      await this.updateSellAndPayments(evaluation, viewData, financing)
    }

    await this.pushData(({
      model: 'Evaluation',
      fields: {
        id,
        id_process_status: this.evaluationStatus.closed[0].id,
        id_closing_reason: this.evaluationClosingReason.signed[0].id,
        operation_number: operationNumber,
        approval_date: fixDate(approvalDate),
      },
    }))

    await this.handleFileType(letterApproval, { properties: approvalProperties }, idProcess, id)
    await this.handleFileType(prohibition, { properties: prohibitionProperties }, idProcess, id)

    const evaluations = financing.evaluations.filter(evaluation => evaluation.id !== id && evaluation.status.isApproved)

    await Promise.all(evaluations.map(async evaluation => {
      await this.pushData(({
        model: 'Evaluation',
        fields: {
          id: evaluation.id,
          id_process_status: this.evaluationStatus.closed[0].id,
          id_closing_reason: this.evaluationClosingReason.resigned[0].id,
        },
      }))
    }))

    await this.pushData(({
      model: 'Offer',
      fields: {
        id: quotas.id,
        accepted: true,
      },
    }))

    const offersToReject = offers.filter(offer => offer.id !== quotas.id)

    await Promise.all(offersToReject.map(async offer => {
      await this.pushData(({
        model: 'Offer',
        fields: {
          id: offer.id,
          accepted: false,
        },
      }))
    }))

    if (!status?.isClosed) {
      await this.handleInterveners(acquirer, documentId, idFieldDocument, id)
    }
  }

  async handleInterveners (acquirer, documentId, idFieldDocument, id) {
    const document = await this.fetchData({
      query: { name: 'fetchLite', model: 'Document', params: { id: documentId } },
      force: true,
    })
    const personsDocument = document?.interveners.filter(item => item.field.name === 'buyer').map(item => item.person) || []
    const acquirerToAdd = acquirer?.filter(item => !personsDocument.includes(item)) || []

    await Promise.all(acquirerToAdd.map(async acq => {
      await this.pushData(({
        model: 'Intervener',
        fields: {
          id_document: documentId,
          id_person: acq?.id,
          uid: acq.uid,
          business_name: acq.fullName,
          id_field: idFieldDocument,
          id_evaluation: id,
        },
      }))
    }))
  }

  async updateSellAndPayments (evaluation, viewData, financing) {
    const { documentId } = viewData
    const statusActive = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'sale_order' } } }, { status: { name: { _eq: 'active' } } }] },
    })

    await this.pushData(({
      model: 'SaleOrder',
      fields: {
        id: financing?.saleOrder.id,
        id_process_status: statusActive[0].id,
        id_document: documentId,
      },
    }))

    await this.updateReserve(financing?.saleOrder)

    const status = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ status: { name: { _eq: 'pending_payment' } } }, { process: { table_name: { _eq: 'payment' } } }] },
    })

    const saleProcess = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'sale_order' } },
    })

    const paymentType = await this.fetchData({
      query: { name: 'find', model: 'PaymentType' },
      filter: { name: { _eq: 'financing' } },
    })

    const payments = await this.fetchData({
      query: { name: 'findLite', model: 'Payment' },
      filter: { _and: [{ id_process_record: { _eq: financing?.saleOrder.id } }, { id_payment_type: { _eq: paymentType?.[0]?.id } }] },
      force: true,
    })

    if (!payments.length) {
      const cost = financing?.requestAmountTransfer || financing?.requestAmount

      const fields = {
        id_payment_type: paymentType[0].id,
        id_process_status: status[0].id,
        id_process_record: financing?.saleOrder?.id,
        id_process: saleProcess[0].id,
        amount: cost,
        date: fixDate(dayjs().format('DD/MM/YYYY')),
        id_payer: evaluation.agreement.person.id,
        id_deal: financing?.saleOrder.deal.id,
      }

      await this.pushData({ model: 'Payment', fields })
    }
    await this.updateStock(financing?.saleOrder)
  }

  async updateReserve (saleOrder) {
    const reserve = await this.fetchData({
      query: { name: 'find', model: 'Reserve' },
      filter: {
        _and: [
          { id_sale_order: { _eq: saleOrder.id } },
          { id_closing_reason: { _is_null: true } },
        ],
      },
    })

    if (reserve?.[0]?.id) {
      const closed = await this.fetchData({
        query: { name: 'find', model: 'ProcessStatus' },
        filter: { _and: [{ process: { table_name: { _eq: 'reserve' } } }, { status: { name: { _eq: 'closed' } } }] },
      })

      const filterSuccess = {
        _and: [
          { type: { name: { _eq: 'successful' } } },
          { status: { process: { table_name: { _eq: 'reserve' } } } },
        ],
      }

      const closing = await this.fetchData({
        query: { name: 'find', model: 'ClosingReason' },
        filter: filterSuccess,
      })

      const reserveFields = {
        id: reserve[0].id,
        id_process_status: closed[0]?.id,
        id_closing_reason: closing[0]?.id,
      }

      await this.pushData({ model: 'Reserve', fields: reserveFields })
    }
  }

  async updateStock (saleOrder) {
    const { deal: { stock: { id } } } = saleOrder
    const status = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ status: { name: { _eq: 'selling' } } }, { process: { table_name: { _eq: 'stock' } } }] },
    })

    const fields = {
      id,
      id_process_status: status[0].id,
    }

    await this.pushData({ model: 'Stock', fields })
  }
}
